import React, { useEffect, useState } from "react"
import styled from 'styled-components'

interface IProps {
  hash: string
  parent: {
    _id: string
    email: string
    name: string
    referralCode: string
  }
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  padding: 30px 20px;
  > * {
    margin-bottom: 10px;
  }
  border-radius: 12px;
  border: 3px solid #ec5e2e;
  max-width: 400px;
  .info {
    text-align: center;
  }
  .tiny {
    font-size: 12px;
  }
  a {
    color: #ec5e2e;
    text-decoration: underline;
  }
`

export const Title = styled.div`
  color: #ec5e2e;
  font-size: 2rem;
  text-align: center;
  font-weight: 700;
`

const ParentThanks = (props: IProps) => {
  const [remaining, setRemaining] = useState(5)
  const dashboardLink = `https://dashboard.nightzookeeper.com/onboarding?userId=${props.parent?._id}&hash=${props.hash}`

  const canProceed = props.parent?._id && props.hash

  const onComplete = () => {
    if (typeof window !== 'undefined') {
      window.location.assign(dashboardLink)
    }
  }

  useEffect(() => {
    if (canProceed) {
      const maxTime = 5
      setRemaining(maxTime)
      let iterations = 0
      const handle = setInterval(() => {
        iterations += 1
        if (iterations > maxTime) {
          clearInterval(handle)
          onComplete()
          return 
        }
        setRemaining(r => r - 1)
      }, 1000)
  
      return () => clearInterval(handle)
    }
  }, [canProceed])

  if (!canProceed) {
    return <Wrapper>
      <Title>Oops...</Title>
      <p className='info'>We've got a bit of an issue retrieving your data.</p>
      <p className='info'>Check your emails for login instructions.</p>
      <p className='info'>If you haven't received an email from us, please contact <a href='mailto:support@nightzookeeper.com'>support@nightzookeeper.com</a></p>
    </Wrapper>
  }

  return <Wrapper>
    <Title>Success</Title>
    <p className='info'>You will be automatically redirected in {remaining} seconds.</p>
    { remaining === 0 && <p className='info tiny'>
      <a href={dashboardLink}>If you are not being redirected, please click here.</a>
    </p> }
  </Wrapper>
}

ParentThanks.defaultProps = {
}

export default ParentThanks
