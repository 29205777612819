import React from "react"
import { Plan } from "../ParentSignupForm"
import { formatAmount } from "../Strapi/StrapiPlanHero"
import { ICoupon } from './Coupon'

interface IProps {
  price: any
  quantity: number
  couponObject?: ICoupon
}

const getPlanComponent = (price, quantity, couponObject) => {
  let name = ""
  let period = ""
  if (price.recurring.interval === "year") {
    name = "Yearly"
    period = "year"
  }
  if (
    price.recurring.interval === "month" &&
    price.recurring.interval_count === 1
  ) {
    name = "Monthly"
    period = "month"
  }
  if (
    price.recurring.interval === "month" &&
    price.recurring.interval_count === 3
  ) {
    name = "Quarterly"
    period = "3 months"
  }

  const { tiers } = price
  const tier = tiers
  // Cleans tiers to remove duplicate flat_amounts and keep the biggest
  .reduce((acc: any, t: any, i: number) => {
    if (acc.length === 0) {
      return [...acc, { ...t, index: i }]
    }
    if (tiers[i + 1] &&  tiers[i + 1].up_to && tiers[i + 1].flat_amount === t.flat_amount) {
      return [...acc, tiers[i + 1]]
    }
    if (t.up_to === null) {
      const maxTier = Math.max(...tiers.map(t => t.up_to))
      return [...acc, { ...t, up_to: maxTier + 1 }]
    }
    return [...acc, t]
  }, [])
  // Searches for maximum matching tier
  .reduce((acc: any, t: any) => {
    if (acc.up_to) return acc
    if (t.up_to && quantity <= t.up_to) {
      return t
    }
    return acc
  }, {})

  if (couponObject) {
    let discountPercent = 0
    let amountOff = 0
    if (couponObject.percent_off) {
      discountPercent = couponObject.percent_off / 100
    }
    if (couponObject.amount_off && couponObject.currency === price.currency) {
      amountOff = couponObject.amount_off
    }
    return (
      <>
        <s>
          <Plan>
            <div>
              <b>
                {name} - {formatAmount(price.currency, tier.up_to * tier.unit_amount + tier.flat_amount)}
              </b>{" "}
              / {period}
            </div>
            <div>
              ( For up to {tier.up_to} child{tier.up_to > 1 ? "ren" : ""} )
            </div>
          </Plan>
        </s>
        <Plan>
          <div>
            <b>
              {name} -{" "}
              {formatAmount(
                price.currency,
                Math.max((((tier.up_to * tier.unit_amount) + tier.flat_amount)- amountOff),  0) * (1 - discountPercent)
              )}
            </b>{" "}
            / {period}
          </div>
          <div>
            ( For up to {tier.up_to} child{tier.up_to > 1 ? "ren" : ""} )
          </div>
        </Plan>
      </>
    )
  }
  return (
    <Plan>
      <div>
        <b>
          {name} - {formatAmount(price.currency, (tier.up_to * tier.unit_amount) + tier.flat_amount)}
        </b>{" "}
        / {period}
      </div>
      <div>
        ( For up to {tier.up_to} child{tier.up_to > 1 ? "ren" : ""} )
      </div>
    </Plan>
  )
}

const SelectedPlan = (props: IProps) => (
  <>{getPlanComponent(props.price, props.quantity, props.couponObject)}</>
)

SelectedPlan.defaultProps = {
  couponObject: undefined,
}

export default SelectedPlan
