import * as Sentry from '@sentry/gatsby'

interface ILogErrorArgs {
  operation?: string,
  user?: { email: string }
}

const useSentry = () => {
  const logError = (err: any, args: ILogErrorArgs, extras?: any) => {
    Sentry.withScope(scope => {
      if (args.operation) {
        scope.setTag('operation', args.operation)
      }
      if (args.user) {
        scope.setUser({
          ...args.user
        })
      }
      Object.keys(extras || {}).map(key => {
        scope.setExtra(key, extras[key])
        return null
      })
      Sentry.captureException(err)
    })
  }

  return { logError }
}

export default useSentry
