import { useApolloClient } from "@apollo/client/react"
import gql from "graphql-tag"
import React, { useState } from "react"
import styled from "styled-components"
import Button from "../UI/Button"
import Input from "../UI/Input"

interface IProps {
  currency: string
  onAppliedCoupon: (value: string, couponObject: ICoupon) => void
}

const CouponLabel = styled.div`
  color: #000;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  font-size: 0.9rem;
  cursor: pointer;
`

const DiscountForm = styled.div`
  display: flex;
  > :first-child {
    margin-right: 15px;
  }
`

const Error = styled.div`
  color: #f00;
  text-align: center;
`

const Applied = styled.div`
  color: #e45d2e;
  text-align: center;
`

export interface ICoupon {
  id: string
  amount_off?: number
  currency: string
  percent_off?: number
}

const Coupon = (props: IProps) => {
  const [displayed, setDisplayed] = useState(false)
  const [coupon, setCoupon] = useState("")
  const [couponObject, setCouponObject] = useState<ICoupon | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [applied, setApplied] = useState(false)
  const client = useApolloClient()

  const applyCoupon = async () => {
    const { data } = await client.query({
      query: gql`
        query getDiscount($couponId: String!) {
          getDiscount (couponId: $couponId) {
            id
            amount_off
            currency
            percent_off
          }
        }
      `,
      variables: {
        couponId: coupon,
      },
    })
    if (data.getDiscount?.amount_off && data.getDiscount?.currency !== props.currency) {
      setError(`Coupon not applicable to this currency.`)
      return
    }
    if (data.getDiscount?.percent_off || data.getDiscount?.amount_off) {
      props.onAppliedCoupon(coupon, data.getDiscount)
      setCouponObject(data.getDiscount)
      setApplied(true)
    } else {
      setError("Coupon not found!")
    }
  }

  return (
    <>
      {!displayed && !applied && (
        <CouponLabel onClick={() => setDisplayed(true)}>
          Do you have a coupon code?
        </CouponLabel>
      )}
      {displayed && !applied && (
        <>
          <DiscountForm>
            <Input
              placeholder="Coupon code"
              value={coupon}
              onChange={e => setCoupon(e.target.value)}
            />
            <Button theme="confirm" size="small" onClick={applyCoupon}>
              Apply
            </Button>
          </DiscountForm>
          {error && !applied && <Error>{error}</Error>}
          <CouponLabel onClick={() => setDisplayed(false)}>
            I don't have a coupon.
          </CouponLabel>
        </>
      )}
      {applied && couponObject?.percent_off && <Applied>{couponObject?.percent_off}% OFF coupon has been applied!</Applied>}
      {applied && couponObject?.amount_off && <Applied>{new Intl.NumberFormat("en-us", {
        style: "currency",
        currency: couponObject.currency,
      }).format(couponObject.amount_off / 100)}OFF coupon has been applied!</Applied>}
    </>
  )
}


export default Coupon
