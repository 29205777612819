import { useLocation } from '@reach/router'
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import styled from "styled-components"
import FooterStrapi from "../components/Footer"
import HeaderStrapi from "../components/Header"
import ParentCheckoutForm from "../components/ParentCheckoutForm"
import ParentSignupForm from "../components/ParentSignupForm"
import ParentThanks from "../components/ParentThanks"
import SEO from "../components/SEO"
import useLocale from "../hooks/useLocale"
import BareLayout from "../layouts/Bare"
import Apollo from "../providers/Apollo"

const Wrapper = styled.div`
  position: relative;
  > :first-child {
    padding-top: 80px;
  }
  > :last-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
`

const Content = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 600px;
`

const Trial = styled.div`
  color: #fff;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  font-size: 1.3rem;
`

export interface IParentAccountData {
  stripeCustomerId: string
  parent: {
    _id: string
    email: string
    name: string
    referralCode: string
    subscription: {
      id: string
      customer: {
        id: string
      }
    }
  }
  hash: string
  kids: {
    _id: string
    username: string
    yearGroup: number
    className: string
  }[]
}

interface IProps {
  pageContext: {
    specialOffer: boolean
  }
  data: {
    file: any
    stripePrice: {
      id: string
      currency: string
      nickname: string
      recurring: {
        interval: "month" | "year"
        interval_count: number
        trial_period_days: number
      }
      tiers: {
          up_to: number
          flat_amount: number
          unit_amount: number
      }[]
      trial_period_days: number
    }
  }
  
}

const CheckoutPageTemplate = (props: IProps) => {
  const { data } = props
  const [step, setStep] = useState("DETAILS")
  const location = useLocation()
  const [accountData, setAccountData] = useState<
    IParentAccountData | undefined
  >()
  const { countryCode } = useLocale()
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode,
    termsAccepted: false,
    marketingAccepted: false,
    childrenAges: [null],
    price: data.stripePrice.id,
    quantity: 1,
  })

  const onSuccess = (data: IParentAccountData) => {
    setAccountData(data)
    try {
      if (typeof window !== 'undefined') {
        window.postMessage({
          event: 'ParentCheckout',
          price: {
            id: props.data.stripePrice.id,
            currency: props.data.stripePrice.currency,
            nickname: props.data.stripePrice.nickname
          },
          stripeCustomerId: data.stripeCustomerId,
          parentAccount: data.parent
        }, location.origin)
      }
    } catch (err) {
      // Ignore
    }

    setStep("THANKS")
  }

  return (
    <BareLayout>
      <Apollo>
        <SEO
          title="Checkout"
          description={`Night Zookeeper parent checkout for plan ${
            data.stripePrice.nickname
          }${
            data.stripePrice.trial_period_days
              ? ` with a ${data.stripePrice.trial_period_days} day free trial!`
              : `!`
          } Price starting`}
          noIndex
        />
        <HeaderStrapi />
        <Wrapper>
          <Content>
            {data.stripePrice.recurring.trial_period_days && (
              <Trial>
                Start your {data.stripePrice.recurring.trial_period_days} day
                free trial
              </Trial>
            )}
            {step === "DETAILS" && (
              <ParentSignupForm
                price={data.stripePrice}
                formData={formData}
                setFormData={setFormData}
                onSubmit={() => {
                  setStep("CHECKOUT")
                }}
              />
            )}
            {step === "CHECKOUT" && (
              <ParentCheckoutForm
                price={data.stripePrice}
                formData={formData}
                specialOffer={Boolean(props.pageContext.specialOffer)}
                setFormData={setFormData}
                onSuccess={data => onSuccess(data)}
                onBack={() => setStep("DETAILS")}
              />
            )}
            {step === "THANKS" && (
              <ParentThanks {...accountData!} />
            )}
          </Content>
          <GatsbyImage image={getImage(data.file)!} alt="" />
        </Wrapper>
        <FooterStrapi />
      </Apollo>
    </BareLayout>
  )
}

export const query = graphql`
  query getPlan($planId: String!) {
    file(relativePath: { eq: "creatables.jpeg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    stripePlan(id: { eq: $planId }) {
      id
      currency
      interval
      interval_count
      amount
      nickname
      trial_period_days
    }
    stripePrice(id: { eq: $planId }) {
      id
      currency
      nickname
      recurring {
        interval
        interval_count
        trial_period_days
      }
      tiers {
        up_to
        flat_amount
        unit_amount
      }
    }
  }
`

export default CheckoutPageTemplate
