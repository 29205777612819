import { useApolloClient } from "@apollo/client/react"
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from "@stripe/react-stripe-js"
import { loadStripe, Source } from "@stripe/stripe-js"
import Loader from 'components/UI/Loader'
import gql from "graphql-tag"
import { FormPrevious } from "grommet-icons"
import Cookies from "js-cookie"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import config from "../../../config"
import useReferral from "../../hooks/useReferral"
import useSentry from '../../hooks/useSentry'
import { Title, Wrapper } from "../ParentSignupForm"
import Button from "../UI/Button"
import CardDetails from "./CardDetails"
import Coupon, { ICoupon } from "./Coupon"
import SelectedPlan from "./SelectedPlan"
import use3DSecure from "./use3DSecure"
// import usePaymentRequest from './usePaymentRequest'

const BackButton = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`

interface IProps {
  onBack: () => void
  specialOffer: boolean
  price: {
    id: string
    currency: string
    nickname: string
    recurring: {
      interval: string
      interval_count: number
      trial_period_days: number
    }
    tiers: {
      up_to: number
      flat_amount: number
      unit_amount: number
    }
  }
  couponObject?: ICoupon
  formData: {
    firstName: string
    lastName: string
    email: string
    countryCode: string
    termsAccepted: boolean
    marketingAccepted: boolean
    childrenAges: number[]
    price: string
    quantity: number
    couponCode?: string
    couponObject?: ICoupon
  }
  setFormData: (data: any) => void
  onSuccess: (data: {
    parent: {
      _id: string
      email: string
      name: string
      referralCode: string
    }
    hash: string
    kids: {
      _id: string
      username: string
      yearGroup: number
      className: string
    }[]
  }) => void
}

const ParentCheckoutForm = (props: IProps) => {
  const elements = useElements()
  const stripe = useStripe()
  const code = useReferral()
  const client = useApolloClient()
  const { formData } = props
  const [nbErrors, setNbErrors] = useState(0)
  const { logError } = useSentry()
  const [paymentError, setPaymentError] = useState<undefined | string>()
  const [loading, setLoading] = useState(false)
  const [finalSource, setFinalSource] = useState<Source | undefined>()
  const [element, threeDHandler, threeDSource, threeDError] = use3DSecure(
    props.price.currency
  )


  const setCoupon = (value, couponObject) => {
    props.setFormData(f => ({
      ...f,
      couponCode: value,
      couponObject,
    }))
  }

  const name = useMemo(
    () => `${formData.firstName} ${formData.lastName}`,
    [formData]
  )

  useEffect(() => {
    if (threeDSource && threeDSource.status === "chargeable") {
      setFinalSource(threeDSource)
    } else if (threeDSource && threeDSource.status === "failed") {
      setNbErrors(n => n + 1)
      setPaymentError("3DSecure Authentication Failed.")
      logError(new Error('3DSecure Authentication Failed'), {
        user: {
          email: formData.email
        },
        operation: 'ParentCheckout'
      }, { ...formData })
    }
  }, [threeDSource])

  useEffect(() => {
    if (threeDError) {
      setNbErrors(n => n + 1)
      setPaymentError("3DSecure Authentication Failed.")
      logError(new Error('3DSecure Authentication Failed'), {
        user: {
          email: formData.email
        },
        operation: 'ParentCheckout'
      }, { ...formData })
    }
  }, [threeDError])

  const submit = async () => {
    if (!elements || !stripe || loading) return
    const cardElement = elements.getElement(CardElement)
    if (!cardElement) return
    setLoading(true)
    const { source, error } = await stripe.createSource(cardElement, {
      type: "card",
      owner: {
        name,
        email: formData.email,
      },
    })
    if (error) {
      setPaymentError(error.message)
      setNbErrors(n => n + 1)
      setLoading(false)
      logError(new Error(error.message), {
        user: {
          email: formData.email
        },
        operation: 'ParentCheckout'
      }, { ...formData })
      return
    }
    if (
      source &&
      source.card &&
      source.card.three_d_secure &&
      ["required"].indexOf(source.card.three_d_secure) >= 0
    ) {
      await threeDHandler(source)
    } else {
      setFinalSource(source)
    }
  }

  const submitParentSubscription = async source => {
    const input = {
      name,
      email: formData.email,
      countryCode: formData.countryCode,
      referralCode: code,
      childAges: formData.childrenAges,
      stripePrice: props.price.id,
      couponCode: formData.couponCode,
      stripeToken: source.id,
      quantity: formData.quantity,
      noTrial:
        !props.price.recurring.trial_period_days ||
        props.price.recurring.trial_period_days === 0,
      nzkReferrer: Cookies.get("nzk_referrer"),
      nbTrialDays: props.price.recurring.trial_period_days,
    }
    const { data, errors } = await client.mutate({
      mutation: gql`
        mutation ($input: SubmitParentSubscriptionV4Input!) {
          submitParentSubscriptionV4(input: $input) {
            error
            parent {
              _id
              email
              name
              referralCode
            }
            hash
            stripeCustomerId
            kids {
              _id
              username
              age
              className
            }
          }
        }
      `,
      variables: { input },
      errorPolicy: 'all'
    })
    setLoading(false)
    if (data.submitParentSubscriptionV4) {
      props.onSuccess(data.submitParentSubscriptionV4)
    }
    if (errors) {
      setLoading(false)
      setNbErrors(n => n + 1)
      const KNOWN_ERRORS = [
        'Your card has expired.',
        'Your card was declined.'
      ]
      if (errors[0] && KNOWN_ERRORS.indexOf(errors[0].message) >= 0) {
        setPaymentError(errors[0].message)
      } else {
        setPaymentError("An error has occured...")
      }
      logError(new Error(errors[0].message), {
        user: {
          email: formData.email
        },
        operation: 'ParentCheckout'
      }, { ...formData })
    }
  }

  // const { loading: loadingPaymentRequest, paymentRequest } = usePaymentRequest({
  //   currency: props.price.currency,
  //   amount: 0,
  //   country: formData.countryCode,
  // }, (token) => submitParentSubscription(token), (error) => {
  //   logError(new Error(error.message), {
  //     user: {
  //       email: formData.email
  //     },
  //     operation: 'ParentCheckout'
  //   }, { ...formData, paymentMethod: 'PaymentRequest' })
  // })

  useEffect(() => {
    if (finalSource) {
      submitParentSubscription(finalSource)
    }
  }, [finalSource])

  return (
    <Wrapper>
      <BackButton>
        <Button size="small" theme="primary" round onClick={props.onBack}>
          <FormPrevious color="#fff" />
        </Button>
      </BackButton>
      {element}
      <Title>Selected Plan</Title>
      <SelectedPlan
        price={props.price}
        quantity={props.formData.quantity}
        couponObject={props.formData.couponObject}
      />
      { !props.specialOffer && <Coupon
        currency={props.price.currency}
        onAppliedCoupon={(coupon, couponObject) => setCoupon(coupon, couponObject)}
      /> }
      <CardDetails paymentError={paymentError} nbErrors={nbErrors} />
      { loading && <Loader color='#EC5E2E' size={40} /> }
      <Button theme="primary" size="small" onClick={submit} disabled={loading}>
        { loading ? 'Loading...' : 'Submit' }
      </Button>
    </Wrapper>
  )
}

ParentCheckoutForm.defaultProps = {
  couponObject: undefined,
}

export default props => {
  const stripePromise = useMemo(() => loadStripe(config.stripeKey), [])

  return (
    <Elements stripe={stripePromise}>
      <ParentCheckoutForm {...props} />
    </Elements>
  )
}
