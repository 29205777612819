import { useLocation } from '@reach/router'
import { useMemo } from 'react'
import { countryCodeForCountry, countryCodeForLocale } from '../utils/intl'

const useLocale = () => {
  const location = useLocation()

  const locale = useMemo(() => {
    const locale = location.pathname.split('/').filter(d => d)[0]
    return locale
  }, [location])

  const countryCode = useMemo(() => {
    const splits = locale.split('-')
    if (splits[1]) {
      return countryCodeForCountry[splits[1]] || splits[1].toUpperCase()
    }
    return countryCodeForLocale[locale] || 'US'
  }, [locale])

  return { locale, countryCode }
}

export default useLocale